<template>
  <div class="grid">
    <Toast />

    <div class="col-12" v-if="orcamentoData">
      <h6 v-if="orcamentoData.id">Editando Orçamento {{ orcamentoData.id }}

      <span :class="'orcamento-badge status-' + (orcamentoData.status ? orcamentoData.status.toLowerCase() : '')"> {{ nomeStatus(orcamentoData.status) }} </span>
      </h6>
      <h6 v-else>Novo Orçamento</h6>
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button class="p-button-success mr-2" label="Salvar" icon="pi pi-save" :loading="loadingSalvar" @click="saveOrcamento" v-if="orcamentoData != null && orcamentoData.status === 'EL'" />
<!--            <Button label="Linha do Tempo" icon="pi pi-sliders-v" class="mr-2 p-button-outlined" @click="sidebarLead = true"  />-->
            <Button label="Sucesso" icon="pi pi-window-maximize" class="mr-2 p-button-outlined" @click="confirmarOrcto()" v-if="orcamentoData != null && orcamentoData.status === 'EL'" />
            <Button label="Nova Etapa" icon="pi pi-bars" class="mr-2 p-button-outlined" @click="sidebarEtapa = true" v-if="orcamentoData != null && orcamentoData.status === 'EL'"/>
            <Button label="Exportar" icon="pi pi-download" class="mr-2 p-button-outlined" :loading="loadingPDF" @click="imprimitPDF()"  />
          </div>
        </template>
        <template v-slot:end>
          <Button label="Voltar" icon="pi pi-backward" class="p-button-outlined mr-2" @click="$router.go(-1)" />
        </template>
      </Toolbar>
    </div>

    <div class="col-12">
      <div  class="custom-skeleton p-4" v-if="loadingData === true">
        <Skeleton width="100%" height="50px"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
      </div>

      <TabView v-if="loadingData === false" >


        <TabPanel>
          <template #header>
            <span>Cliente</span>
            <i class="pi pi-user ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">

            <div class="field col-12 md:col-6">
              <label for="nome">Nome</label>

              <AutoComplete
                  placeholder="Pesquisar cliente"
                  :class="orctoclienteData.length === 0 ? 'p-invalid' : ''"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValueCliente"
                  :suggestions="autoFilteredCliente"
                  @complete="searchCliente($event)"
                  field="nome"
                  :disabled="orcamentoData != null && orcamentoData.status !== 'EL'"
                  @item-select="selecionaCliente($event)">

                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 30%">{{slotProps.item.nome}}</div>
                    <div style="width: 20%">{{slotProps.item.cnpj}}</div>
                    <div style="width: 20%">{{slotProps.item.cidade}}</div>
                    <div style="width: 20%">{{slotProps.item.estado}}</div>
                  </div>
                </template>
              </AutoComplete>
            </div>

          </div>
          <div class="p-fluid formgrid grid" v-if="orctoclienteData">

            <div class="field col-12 md:col-3">
              <label for="cnpj">CPF / CNPJ</label>
              <div class="p-inputgroup">
                <InputText v-model="orctoclienteData.cnpj" :readonly="true" />
                <!--                <Button label="Pesquisar CPF"/>-->
              </div>
            </div>

            <div class="field col-12 md:col-3">
              <label for="email">E-mail</label>
              <InputText id="email" type="text" v-model="orctoclienteData.email" :readonly="true" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="tel1">Telefone</label>
              <InputText id="tel1" type="text" v-model="orctoclienteData.tel1" :readonly="true" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="cep">CEP</label>
              <div class="p-inputgroup">
                <InputText placeholder="00.000-000" v-model="orctoclienteData.cep" :readonly="true" />
              </div>
            </div>

            <div class="field col-12 md:col-3">
              <label for="cidade">Cidade</label>
              <InputText id="cidade" type="text" v-model="orctoclienteData.cidade" :readonly="true" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="rua">Logradouro</label>
              <InputText id="rua" type="text" v-model="orctoclienteData.rua" :readonly="true" />
            </div>
            <div class="field col-12 md:col-3">
              <label for="numero">Número</label>
              <InputText id="numero" type="text" v-model="orctoclienteData.numero" :readonly="true" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="estado">Estado</label>
              <InputText id="estado" type="text" v-model="orctoclienteData.estado" :readonly="true" />
            </div>

            <div class="field col-12">
              <label for="obs">Observações</label>
              <Textarea id="obs" rows="4" v-model="orctoclienteData.observacao"  :readonly="true" />
            </div>

            <div class="col-12 md:col-12 text-center mt-3">

              <Button label="Abrir no Mapa" icon="pi pi-map" class="p-button-outlined mr-2" @click="abrirMapCli()" :style="{width: '30%'}"/>
              <Button label="Novo Cliente" icon="pi pi-plus" class="p-button-outlined mr-2" @click="novoCliente()" :style="{width: '30%'}" />
              <Button label="Detalhes Cliente" icon="pi pi-eye" class="p-button-outlined mr-2" @click="editCliente()" :style="{width: '30%'}" />


            </div>

          </div>
        </TabPanel>

        <TabPanel>

          <template #header>
            <span>Sistema</span>
            <i class="pi pi-box ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid" v-if="orctosistData">

            <Divider layout="horizontal">
              <b>Levantamento Técnico</b>
              <i class="pi pi-map ml-2"></i>
            </Divider>


            <div class="field col-12 md:col-3">
              <label for="incidsol">Incidência Solar</label>
              <InputNumber id="incidsol" v-model="orctosistData.incidsol" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="perfil">Perfil Padrão</label>
              <Dropdown
                  id="perfil"
                  :class="!orctosistData.perfil ? 'p-invalid' : ''"
                  v-model="orctosistData.perfil"
                  :options="padraoItems"
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Selecione o perfil do padrão"  >
              </Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="tipopad">Tipo Padrão</label>
              <Dropdown
                  id="tipopad"
                  :class="!orctosistData.tipopad ? 'p-invalid' : ''"
                  v-model="orctosistData.tipopad"
                  :options="tipopadraoItems"
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Selecione o tipo do padrão">
              </Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="tenspad">Tensão Padrão</label>
              <Dropdown
                  id="tenspad"
                  :class="!orctosistData.tenspad ? 'p-invalid' : ''"
                  v-model="orctosistData.tenspad"
                  :options="tensaopadraoItems"
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Selecione a tensão do padrão">
              </Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="cordisj">Corrente Disjuntor (a)</label>
              <InputText id="cordisj" type="text" v-model="orctosistData.cordisj"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="cabdisj">Cabeamento Disjuntor</label>
              <InputNumber id="cabdisj" v-model="orctosistData.cabdisj"/>
            </div>

            <Divider layout="horizontal">
              <b>Consumo</b>
              <i class="pi pi-power-off ml-2"></i>
            </Divider>

            <div class="field col-12 md:col-3">
              <label for="consmes2">Consumo mês</label>
              <InputNumber id="consmes2" v-model="orctosistData.consmes"/>
            </div>

            <div class="field col-12 md:col-3">
              <label for="consdia">Consumo diário</label>
              <InputNumber id="consdia" v-model="orctosistData.consdia" :readonly="true" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>
            <div class="field col-12 md:col-3">
              <label for="ppico1">PPICO1</label>
              <InputNumber id="ppico1" v-model="orctosistData.ppico1" :readonly="true" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>
            <div class="field col-12 md:col-3">
              <label for="ppico2">PPICO2</label>
              <InputNumber id="ppico2" v-model="orctosistData.ppico2" :readonly="true" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

          </div>

        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Cotação</span>
            <i class="pi pi-eye ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">


            <div class="field col-12 md:col-4">
              <label for="consmes">Consumo mês</label>
              <InputNumber id="consmes" v-model="orctosistData.consmes"  :class="!orctosistData.consmes ? 'p-invalid' : ''"/>
            </div>

            <div class="field col-12 md:col-4">
              <div class="field">
                <label for="tensao">Tensão</label>
                <Dropdown id="tensao"
                          v-model="orcamentoData.tensao"
                          :options="itemsTensao"
                          required="true"
                          optionLabel="name"
                          optionValue="code"
                          placeholder="tensão"
                          :class="!orcamentoData.tensao ? 'p-invalid' : ''">
                </Dropdown>
                <small class="p-invalid" v-if="getcotacao && !orcamentoData.tensao">Campo obrigatório.</small>
              </div>
            </div>

            <div class="field col-12 md:col-4">
              <div class="field">
                <label for="estrutura">Estrutura</label>
                <Dropdown id="estrutura"
                          v-model="orcamentoData.estrutura"
                          :options="itemsEstrutura"
                          required="true"
                          optionLabel="name"
                          optionValue="code"
                          placeholder="estrutura"
                          :class="!orcamentoData.estrutura ? 'p-invalid' : ''">
                </Dropdown>
                <small class="p-invalid" v-if="getcotacao && !orcamentoData.estrutura">Campo obrigatório.</small>
              </div>
            </div>

            <div class="field col-12 md:col-3">
              <div class="field">
                <label for="potencia">Potência</label>
                <InputNumber id="potencia" v-model="orcamentoData.potencia" mode="decimal" locale="pt-BR" :minFractionDigits="2" required="true" :readonly="true" :class="{'p-invalid': getcotacao && orcamentoData.potencia <= 0}" />
                <small class="p-invalid" v-if="getcotacao && orcamentoData.potencia <= 0">Potência deve ser maior que zero.</small>
              </div>
            </div>

            <div class="field col-12 md:col-3">
              <div class="field">
                <label for="kitsistema">Módulo</label>
                <InputText id="kitsistema" v-model="orcamentoData.kitsistema"  :readonly="true" />
              </div>
            </div>


            <div class="field col-12 md:col-3">
              <label for="p">P</label>
              <InputNumber id="p" v-model="orctosistData.p" :readonly="true" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>
            <div class="field col-12 md:col-3">
              <label for="modulos">Número de Módulos</label>
              <InputText id="modulos" v-model="orctosistData.modulos"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="areainst">Área Necessária</label>
              <InputNumber id="areainst" v-model="orctosistData.areainst" :readonly="true" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>
            <div class="field col-12 md:col-3">
              <label for="areadisp">Área Disponível</label>
              <InputNumber id="areadisp" v-model="orctosistData.areadisp" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

            <div class="field col-12 md:col-6" v-if="orctocotaData">
              <label for="livre1">Número Pedido</label>
              <InputText id="livre1" v-model="orctocotaData.livre1" :readonly="true"/>
            </div>



            <div class="field col-12">
              <label for="obs">Observações</label>
              <Textarea id="obs" rows="4" v-model="orcamentoData.obs"/>
            </div>


            <Divider layout="horizontal">
              <b>Nova Cotação</b>
              <i class="pi pi-search ml-2"></i>
            </Divider>


            <div class="field col-12 md:col-3">
              <div class="field">
                <label for="modulo">Módulo Cotação</label>
                <Dropdown id="modulo"
                          v-model="orcamentoData.modulo"
                          :options="itemsModulo"
                          required="true"
                          optionLabel="name"
                          optionValue="code"
                          :showClear="true"
                          placeholder="escolher módulo ou vazio para melhor módulo">
                </Dropdown>
              </div>
            </div>



            <div class="field col-3" v-if="orctocotaData && orctocotaData.id">
              <label for="cotapotencia">Potência</label>
              <div class="p-inputgroup">
                <InputNumber id="cotapotencia" v-model="orctocotaData.potencia" :readonly="true" mode="decimal" locale="pt-BR" :minFractionDigits="2"  :class="{'p-invalid': getcotacao && orcamentoData.potencia <= 0}" />
              </div>
            </div>

            <div class="field col-3" v-if="orctocotaData && orctocotaData.id">
              <label for="cotavalor">Valor</label>
              <div class="p-inputgroup">
                <InputNumber id="cotavalor" v-model="orctocotaData.valor" :readonly="true" mode="decimal" locale="pt-BR" :minFractionDigits="2"  :class="{'p-invalid': getcotacao && orcamentoData.potencia <= 0}" />

              </div>
            </div>

            <div class="field col-3 text-right" v-if="orctocotaData && orctocotaData.id">
              <img :src="orctocotaData.img1" :alt="orctocotaData.id" class="my-4 md:my-0 w-9 md:w-6rem mr-0 md:mr-5" />
              <img :src="orctocotaData.img2" :alt="orctocotaData.id" class="my-4 md:my-0 w-9 md:w-6rem mr-0 md:mr-5" />
            </div>


            <div class="col-12 md:col-12 text-center">
              <Button label="Abrir Cotação" icon="pi pi-briefcase" class="p-button-outlined mr-2" @click="abrirPedido()" :style="{width: '20%'}" />
              <Button label="Incr. Cabos e Grampos" icon="pi pi-plus" class="p-button-outlined mr-2" @click="incrementarSistemas()" :style="{width: '20%'}" :loading="loadingIncrSist" :disabled="orcamentoData != null && orcamentoData.status !== 'EL'" v-if="orcamentoData != null && orcamentoData.status === 'EL'"/>
              <Button label="Atualizar Cotação" icon="pi pi-sync" class="p-button-outlined mr-2" @click="atualizarSistemas()" :style="{width: '20%'}" :loading="loadingAtuCota" :disabled="orcamentoData != null && orcamentoData.status !== 'EL'" v-if="orcamentoData != null && orcamentoData.status === 'EL'"/>
              <Button label="Nova Cotação" icon="pi pi-eye" class="p-button-outlined mr-2" @click="cotarSistemas()" :style="{width: '30%'}" :disabled="orcamentoData != null && orcamentoData.status !== 'EL'" v-if="orcamentoData != null && orcamentoData.status === 'EL'"/>
            </div>

          </div>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Valores</span>
            <i class="pi pi-dollar ml-2"></i>
          </template>

          <BlockViewer
              header="Falta de Informação"
              v-if="orctovalorData && (!orctosistData.perfil || !orctosistData.tipopad || !orctosistData.tenspad)"
          >
            <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
              <div class="mb-3 font-bold text-2xl">
                <span class="text-900">Favor revisar </span>
                <span class="text-blue-600"> todas as outras Abas </span>
                <span class="text-900">e preencher todos os campos obrigatórios, marcado(s) com </span>
                <span class="text-red-600">vermelho</span>
              </div>
              <div class="text-700 text-sm mb-6">Falta de informação para acesso a valores</div>
            </div>
          </BlockViewer>

          <div class="p-fluid formgrid grid"
               v-if="orctovalorData && orctosistData.perfil && orctosistData.tipopad && orctosistData.tenspad">




            <Divider layout="horizontal">
              <b>Global</b>
              <i class="pi pi-globe ml-2"></i>
            </Divider>

            <div class="field col-12 md:col-3">
              <label for="invkit">Investimento kit solar</label>
              <InputNumber id="invkit" v-model="orctovalorData.invest" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="invgest">Investimento gestão/instalação</label>
              <InputNumber id="invgest" v-model="orctovalorData.valorf" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3">
              <label for="grisco">Grau de Risco Instalação</label>
              <Dropdown
                  id="grisco"
                  :class="!orctosistData.grisco ? 'p-invalid' : ''"
                  v-model="orctosistData.grisco"
                  :options="riscoinstItems"
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Selecione o risco da instalação" >
              </Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="valortotal">Valor Total</label>
              <InputNumber id="valortotal" v-model="orctovalorData.valortotal" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="partgest">Participação gestão (Mín de 20%)</label>
              <InputNumber id="partgest" v-model="orctovalorData.partgest" prefix="% " :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="margemnegocia">Desconto da margem (Máx de 12%)</label>
              <InputNumber id="margemnegocia" v-model="orctovalorData.margemnegocia" prefix="% " :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="desccomis">Desconto da comissão (Máx de 3%)</label>
              <InputNumber id="desccomis" v-model="orctovalorData.desccomis" prefix="% " :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="descdbi">Desconto DBI (Máx de 30%)</label>
              <InputNumber id="descdbi" v-model="orctovalorData.descdbi" prefix="% " :readonly="true"/>
            </div>


            <Divider layout="horizontal">
              <b>Custos Operacionais</b>
              <i class="pi pi-user-minus ml-2"></i>
            </Divider>

            <div class="field col-12 md:col-3">
              <label for="fatproj">Faturamento projetado</label>
              <InputNumber id="fatproj" v-model="orctovalorData.fatproj" mode="currency" currency="BRL" locale="pt-BR"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="fatmarg">Margem</label>
              <InputNumber id="fatmarg" v-model="orctovalorData.fatmarg" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="fatcfix">Custo fixo</label>
              <InputNumber id="fatcfix" v-model="orctovalorData.fatcfix" mode="currency" currency="BRL" locale="pt-BR"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="fatpartic">Participação projeto</label>
              <InputNumber id="fatpartic" v-model="orctovalorData.fatpartic" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>


            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="maoobra">Mão de obra instalação</label>
            </div>
            <div class="field col-12 md:col-3">
              <label for="maoobrap"> &ensp;</label>
              <InputNumber id="maoobra" v-model="orctovalorData.maoobra" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="maoobrap">Praticado</label>
              <InputNumber id="maoobrap" v-model="orctovalorData.maoobrap" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-3">
              <label for="maoobrar">Referência</label>
              <InputNumber id="maoobrar" v-model="orctovalorData.maoobrar" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="art">ART</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="art" v-model="orctovalorData.art" mode="currency" currency="BRL" locale="pt-BR" />
            </div>

            <div class="field col-12 md:col-6">
              <label for="artp"> &ensp;</label>
            </div>

            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="matele">Material Elétrico</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="matele" v-model="orctovalorData.matele" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="matelep" v-model="orctovalorData.matelep" mode="currency" currency="BRL" locale="pt-BR" />
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="mateler" v-model="orctovalorData.mateler" prefix="% " :readonly="true"/>
            </div>


            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="engen">Engenharia</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="engen" v-model="orctovalorData.engen" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="engenp" v-model="orctovalorData.engenp" mode="currency" currency="BRL" locale="pt-BR" />
            </div>

            <div class="field col-12 md:col-3 align-items-center">
              <label for="custoopr" >Tabela</label>
            </div>


            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="apolog">Apoio Logístico</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="apolog" v-model="orctovalorData.apolog" mode="currency" currency="BRL" locale="pt-BR" />
            </div>

            <div class="field col-12 md:col-6">
              <label for="apologp"></label>
            </div>

            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="custoopr">Custos Operacionais</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="custoopr" v-model="orctovalorData.custoopr" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="custooprp" v-model="orctovalorData.custooprp" mode="currency" currency="BRL" locale="pt-BR"/>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="custooprr" v-model="orctovalorData.custooprr" prefix="% " :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="comiss">Comissão Venda</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="comiss" v-model="orctovalorData.comiss" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="comissp" v-model="orctovalorData.comissp" prefix="% " mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="comissr" v-model="orctovalorData.comissr" prefix="% " :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="imposto">Imposto</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="imposto" v-model="orctovalorData.imposto" mode="currency" currency="BRL" locale="pt-BR"/>
            </div>

            <div class="field col-12 md:col-3">
              <label for="comissap"></label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="impostor" v-model="orctovalorData.impostor" prefix="% " :readonly="true"/>
            </div>


            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="dbi">DBI</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="dbi" v-model="orctovalorData.dbi" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="dbip" v-model="orctovalorData.dbip" prefix="% " mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="dbir" v-model="orctovalorData.dbir" prefix="% " :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3 text-right align-self-end">
              <label for="margneg">Margem de Negociação</label>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="margneg" v-model="orctovalorData.margneg" mode="currency" currency="BRL" locale="pt-BR" :readonly="true"/>
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="margnegp" v-model="orctovalorData.margnegp" prefix="% " mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

            <div class="field col-12 md:col-3">
              <InputNumber id="margnegr" v-model="orctovalorData.margnegr" prefix="% " :readonly="true"/>
            </div>


          </div>

          <Divider layout="horizontal" v-if="orctovalorData && orctosistData.perfil && orctosistData.tipopad && orctosistData.tenspad">
            <b>Faixas de Venda</b>
            <i class="pi pi-chart-line ml-2"></i>
          </Divider>

          <div class="grid mt-3" v-if="indicadoresData && indicadoresData.totalfx1 > 0 && orctosistData.perfil && orctosistData.tipopad && orctosistData.tenspad">

            <div class="col-12 md:col-3 lg:col-3">
              <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block text-600 font-medium mb-3" >
                      <span class="text-300 mr-1">Faixa 1 </span>{{ formatDecimal(indicadoresData.totalfx1 / orctosistData.p) }} <span class="text-200 ml-1"> R$ / KWp </span>
                    </span>
                    <div class="text-900 font-medium text-xl">Total {{ formatCurrency(indicadoresData.totalfx1) }}</div>
                  </div>
                  <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-check-circle text-blue-500 text-xl"></i>
                  </div>
                </div>
                <div class="flex justify-content-between">
                  <div>
                    <span class="text-green-500 font-medium">{{ formatCurrency(indicadoresData.comicfx1) }} </span>
                    <span class="text-500 ml-2"></span>
                  </div>
                  <div class="flex align-items-center justify-content-center">
                    <RadioButton id="option1" name="option" value="1" v-model="orctosistData.faixa" @change="selecionarFaixa()" />
                    <label for="option1"></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 md:col-3 lg:col-3">
              <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block text-500 font-medium mb-3">
                      <span class="text-300 mr-1">Faixa 2 </span>{{ formatDecimal(indicadoresData.totalfx2 / orctosistData.p) }} <span class="text-200 ml-1"> R$ / KWp </span>
                    </span>
                    <div class="text-900 font-medium text-xl">Total {{ formatCurrency(indicadoresData.totalfx2) }}</div>
                  </div>
                  <div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-exclamation-circle text-green-500 text-xl"></i>
                  </div>
                </div>
                <div class="flex justify-content-between">
                  <div>
                    <span class="text-green-500 font-medium">{{ formatCurrency(indicadoresData.comicfx2) }} </span>
                    <span class="text-500 ml-2"></span>
                  </div>
                  <div class="flex align-items-center justify-content-center">
                    <RadioButton id="option1" name="option" value="2" v-model="orctosistData.faixa" @change="selecionarFaixa()" />
                    <label for="option1"></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 md:col-3 lg:col-3">
              <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block text-500 font-medium mb-3">
                      <span class="text-300 mr-1">Faixa 3 </span>{{ formatDecimal(indicadoresData.totalfx3 / orctosistData.p) }} <span class="text-200 ml-1"> R$ / KWp </span>
                    </span>
                    <div class="text-900 font-medium text-xl">Total {{ formatCurrency(indicadoresData.totalfx3) }}</div>
                  </div>
                  <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-ban text-orange-500 text-xl"></i>
                  </div>
                </div>
                <div class="flex justify-content-between">
                  <div>
                    <span class="text-green-500 font-medium">{{ formatCurrency(indicadoresData.comicfx3) }} </span>
                    <span class="text-500 ml-2"></span>
                  </div>
                  <div class="flex align-items-center justify-content-center">
                    <RadioButton id="option1" name="option" value="3" v-model="orctosistData.faixa" @change="selecionarFaixa()" />
                    <label for="option1"></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 md:col-3 lg:col-3">
              <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block text-500 font-medium mb-3">
                      <span class="text-300 mr-1">Faixa 4 </span>{{ formatDecimal(indicadoresData.totalfx4 / orctosistData.p) }} <span class="text-200 ml-1"> R$ / KWp </span>
                    </span>
                    <div class="text-900 font-medium text-xl">Total {{ formatCurrency(indicadoresData.totalfx4) }}</div>
                  </div>
                  <div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-lock text-red-500 text-xl"></i>
                  </div>
                </div>
                <div class="flex justify-content-between">
                  <div>
                    <span class="text-green-500 font-medium">{{ formatCurrency(indicadoresData.comicfx4) }} </span>
                    <span class="text-500 ml-2"></span>
                  </div>
                  <div class="flex align-items-center justify-content-center">
                    <RadioButton id="option1" name="option" value="4" v-model="orctosistData.faixa" @change="selecionarFaixa()" />
                    <label for="option1"></label>
                  </div>
                </div>
              </div>

            </div>



          </div>

        </TabPanel>



        <TabPanel>
          <template #header>
            <span>Geração</span>
            <i class="pi pi-chart-bar ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid" v-if="orctosistData && indicadGeracao">

            <Divider layout="horizontal">
              <b>Entradas</b>
              <i class="pi pi-sign-in ml-2"></i>
            </Divider>

            <div class="field col-12 md:col-3">
              <label for="consmed">Consumo médio (kWh)</label>
              <InputNumber id="consmed" v-model="orctosistData.consmed"  />
            </div>
            <div class="field col-12 md:col-3">
              <label for="dispon">Disponibilidade (kWh)</label>
              <InputNumber id="dispon" v-model="orctosistData.dispon" />
            </div>
            <div class="field col-12 md:col-3">
              <label for="geracdesej">Geração desejada (kWh)</label>
              <InputNumber id="geracdesej" v-model="indicadGeracao.geracdesej" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="irradmedia">Irradiação Média Local (kWh/m² x dia)</label>
              <InputNumber id="irradmedia" v-model="indicadGeracao.irradmedia" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="mediames">Média de Dias por Mês</label>
              <InputNumber id="mediames" value="30" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="fatcorutil">Fator de Correção Utilizado</label>
              <InputNumber id="fatcorutil" value="0,80" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="gantracker">Ganho de Produção com Tracker (%)</label>
              <InputNumber id="gantracker" value="20,85%" :readonly="true"/>
            </div>



            <Divider layout="horizontal">
              <b>Saídas</b>
              <i class="pi pi-sign-out ml-2"></i>
            </Divider>

            <div class="field col-12 md:col-3">
              <label for="potusina">Potência da Usina (kWp)</label>
              <InputNumber id="potusina" v-model="orctosistData.modulos" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="potinv">Somatório da Potência dos Inversores (kW) - Mínimo</label>
              <InputNumber id="potinv" v-model="orctosistData.potinv"/>
            </div>
            <div class="field col-12 md:col-3">

            </div>
            <div class="field col-12 md:col-3">
              <label for="sistmodulos">Nº Módulos</label>
              <InputNumber id="sistmodulos" v-model="orctosistData.modulos" :readonly="true"/>
            </div>


            <div class="field col-12 md:col-3">
              <label for="germedmes">Geração Média Mensal (kWh/mês)</label>
              <InputNumber id="germedmes" v-model="indicadGeracao.germedmes" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">
              <label for="germedano">Geração Anual (kWh/ano)</label>
              <InputNumber id="germedano" v-model="indicadGeracao.germedano" :readonly="true"/>
            </div>
            <div class="field col-12 md:col-3">

            </div>
            <div class="field col-12 md:col-3">
              <label for="produtiv">Produtividade Média (kWh/kWp/ano)</label>
              <InputNumber id="produtiv" v-model="indicadGeracao.produtiv" :readonly="true"/>
            </div>

          </div>

          <DataTable v-if="irradItensData" :value="irradItensData" :scrollable="false" scrollHeight="400px" :loading="loadingLeads" scrollDirection="vertical" class="mt-3">
            <Column field="descricao" header="Estrutura Fixa" :style="{width:'20%'}" ></Column>
            <Column field="jan" header="Jan" class="text-right"></Column>
            <Column field="fev" header="Fev" class="text-right"></Column>
            <Column field="mar" header="Mar" class="text-right"></Column>
            <Column field="abr" header="Abr" class="text-right"></Column>
            <Column field="mai" header="Mai" class="text-right"></Column>
            <Column field="jun" header="Jun" class="text-right"></Column>
            <Column field="jul" header="Jul" class="text-right"></Column>
            <Column field="ago" header="Ago" class="text-right"></Column>
            <Column field="set" header="Set" class="text-right"></Column>
            <Column field="out" header="Out" class="text-right"></Column>
            <Column field="nov" header="Nov" class="text-right"></Column>
            <Column field="dez" header="Dez" class="text-right"></Column>
          </DataTable>


          <div class="grid">
            <div class="col-4 flex align-items-center justify-content-center">
              <div class="p-fluid">
                <DataTable v-if="tabperdasData" :value="tabperdasData" :scrollable="false" scrollHeight="400px" :loading="loadingLeads" scrollDirection="vertical" class="mt-3" style="min-height: 600px;">
                  <Column field="descricao" header="Perdas" :style="{width:'50%'}" ></Column>
                  <Column field="valor" header="" :style="{width:'50%'}" class="text-right"></Column>
                </DataTable>
              </div>
            </div>



            <div class="col-8 align-items-center justify-content-center mt-4">
              <Chart type="bar" :data="dsGraphGeracao" :options="barOptions"></Chart>
            </div>

          </div>

        </TabPanel>





        <TabPanel>
          <template #header>
            <span>Dimensionamento</span>
            <i class="pi pi-table ml-2"></i>
          </template>

          <div class="grid" style="min-height: 1300px;">
            <div class="col-6 flex align-items-start justify-content-start">
              <div class="p-fluid">
                <DataTable v-if="tabdimensData" :value="tabdimensData" :scrollable="false" scrollHeight="400px" :loading="loadingLeads" scrollDirection="vertical" class="mt-3">
                  <Column field="descricao" header="DIMENSIONAMENTO FOTOVOLTAICO" :style="{width:'70%'}" ></Column>
                  <Column field="valor" header="" :style="{width:'10%'}" class="text-right"></Column>
                  <Column field="unidade" header="" :style="{width:'10%'}" class="text-right"></Column>
                </DataTable>
              </div>
            </div>

            <div class="col-6 flex align-items-start justify-content-start">
              <div class="p-fluid">
                <DataTable v-if="tabretinvestData" :value="tabretinvestData" :scrollable="false" scrollHeight="400px" :loading="loadingLeads" scrollDirection="vertical" class="mt-3">
                  <Column field="status" header="STATUS" :style="{width:'70%'}" ></Column>
                  <Column field="calculo" header="CÁLCULO" :style="{width:'10%'}" class="text-right"></Column>
                  <Column field="ano" header="ANO" :style="{width:'10%'}" class="text-right"></Column>
                  <Column field="economia" header="ECONOMIA ANUAL" :style="{width:'10%'}" class="text-right"></Column>
                  <Column field="ajuste" header="AJUSTE ANUAL" :style="{width:'10%'}" class="text-right"></Column>
                  <Column field="perda" header="PERDA EFICIÊNCIA" :style="{width:'10%'}" class="text-right"></Column>
                </DataTable>
              </div>
            </div>

          </div>


        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Materiais</span>
            <i class="pi pi-server ml-2"></i>
          </template>

          <DataTable v-if="orctoitemsData" :value="orctoitemsData" :scrollable="false" scrollHeight="400px" :loading="loadingLeads" scrollDirection="vertical" class="mt-3" style="min-height: 1000px;">

            <Column field="id" header="Id" :style="{width:'10%'}" ></Column>
            <Column field="codigo" header="Código" :style="{width:'20%'}" ></Column>

            <Column header="Descrição" :style="{width:'50%'}">
              <template #body="slotProps" >
                {{slotProps.data.descricao}}
              </template>
            </Column>

            <Column field="qtd" header="Quantidade"  class="text-right" :style="{width:'10%'}" >
              <template #body="slotProps">
                <span class="p-column-title">Quantidade</span>
                {{formatDecimal(slotProps.data.qtd)}}
              </template>
            </Column>

<!--            <Column header="Ações" class="text-right" :style="{width:'10%'}" >-->
<!--              <template #body="">-->
<!--                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" />-->
<!--              </template>-->
<!--            </Column>-->

          </DataTable>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Cronograma</span>
            <i class="pi pi-calendar ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid" v-if="orctocronoData">
            <div class="field col-12 md:col-3">
              <label for="validade">Validade</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="orctocronoData.validade" dateFormat="dd/mm/yy"></Calendar>
            </div>
            <div class="field col-12 md:col-3">
              <label for="etp1ini">Contratação do Serviço</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="orctocronoData.etp1ini" dateFormat="dd/mm/yy"></Calendar>
            </div>
            <div class="field col-12 md:col-3">
              <label for="etp2ini">Documentação Engenharia</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="orctocronoData.etp2ini" dateFormat="dd/mm/yy"></Calendar>
            </div>
            <div class="field col-12 md:col-3">
              <label for="etp3ini">Aprovação da Companhia</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="orctocronoData.etp3ini" dateFormat="dd/mm/yy"></Calendar>
            </div>
            <div class="field col-12 md:col-3">
              <label for="etp4ini">Entrega do Equipamento</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="orctocronoData.etp4ini" dateFormat="dd/mm/yy"></Calendar>
            </div>
            <div class="field col-12 md:col-3">
              <label for="etp5ini">Instalação da Usina</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="orctocronoData.etp5ini" dateFormat="dd/mm/yy"></Calendar>
            </div>
            <div class="field col-12 md:col-3">
              <label for="etp6ini">Homologação</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="orctocronoData.etp6ini" dateFormat="dd/mm/yy"></Calendar>
            </div>
          </div>

          <div class="text-center">
            <img alt="main logo" class="app-logo" :src="'layout/images/pages/cronograma01.png'">
          </div>
        </TabPanel>



        <TabPanel v-if='financData'>
          <template #header>
            <span>Financiamento</span>
            <i class="pi pi-money-bill ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">

            <div class="field col-12 md:col-6">
              <label for="banco">Banco</label>
              <Dropdown id="banco" v-model="financData.banco" :options="bancoItems" optionLabel="name" optionValue="code" placeholder="Selecione o banco"  ></Dropdown>
            </div>

            <div class="field col-12 md:col-6">
              <label for="valorentr">Valor Entrada</label>
              <InputNumber id="valorentr" v-model="financData.entrada" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc1">12x</label>
              <InputNumber id="parc1" v-model="financData.p12x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc2">24x</label>
              <InputNumber id="parc2" v-model="financData.p24x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc3">36x</label>
              <InputNumber id="parc3" v-model="financData.p36x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc4">48x</label>
              <InputNumber id="parc4" v-model="financData.p48x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc5">60x</label>
              <InputNumber id="parc5" v-model="financData.p60x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc6">72x</label>
              <InputNumber id="parc6" v-model="financData.p72x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc7">84x</label>
              <InputNumber id="parc7" v-model="financData.p84x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc8">96x</label>
              <InputNumber id="parc8" v-model="financData.p96x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc9">108x</label>
              <InputNumber id="parc9" v-model="financData.p108x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="parc10">120x</label>
              <InputNumber id="parc10" v-model="financData.p120x" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
          </div>

        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Timeline</span>
            <i class="pi pi-id-card ml-2"></i>
          </template>

          <DataTable v-if="etapasData" :value="etapasData" :scrollable="true" scrollHeight="400px" :loading="loadingData" scrollDirection="both" class="mt-3">

            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/leads')" :disabled="true"/>
              </template>
            </Column>

            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>

            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.data)}}
              </template>
            </Column>

            <Column field="etapa" header="Etapa" :style="{width:'200px'}" ></Column>
            <Column field="created_by" header="Usuário" :style="{width:'200px'}" ></Column>
            <Column field="obs" header="Observação" :style="{width:'300px'}"></Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'lead-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>

          </DataTable>


        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Vendas</span>
            <i class="pi pi-shopping-cart ml-2"></i>
          </template>

          <DataTable v-if="pedidosData" :value="pedidosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/pedidodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'pedido-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Obras</span>
            <i class="pi pi-briefcase ml-2"></i>
          </template>
          <DataTable v-if="obrasData" :value="obrasData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/obradetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'obra-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>



        <TabPanel>
          <template #header>
            <span>Projetos</span>
            <i class="pi pi-sitemap ml-2"></i>
          </template>

          <DataTable v-if="projetosData" :value="projetosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/projetodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'projeto-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>

      </TabView>


    </div>


    <Sidebar v-model:visible="sidebarEtapa" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <h5>Nova Etapa</h5>

      <div class="grid p-fluid mt-3" >

        <div class="col-12 md:col-12">
          <label for="tipoocor">Tipo</label>
          <div class="p-inputgroup">
            <Dropdown id="tipoocor"
                      v-model="etapaData.tipo"
                      :options="etapaItems"
                      optionLabel="name"
                      optionValue="code"
                      :class="validEtapa && !etapaData.tipo ? 'p-invalid' : ''"
                      placeholder="Selecione o tipo">
            </Dropdown>
            <small class="p-invalid" v-if="validEtapa && !etapaData.tipo">Campo obrigatório.</small>
          </div>
        </div>

        <div class="col-12 md:col-12">
          <label for="data">Data</label>
          <div class="p-inputgroup">
            <Calendar :showIcon="true"
                      :showButtonBar="true"
                      v-model="etapaData.data"
                      :class="validEtapa && !etapaData.data ? 'p-invalid' : ''"
                      dateFormat="dd/mm/yy">
            </Calendar>
            <small class="p-invalid" v-if="validEtapa && !etapaData.data">Campo obrigatório.</small>
          </div>
        </div>




        <div class="col-12 md:col-12" >
          <label for="obsocor">Observações</label>
          <div class="p-inputgroup">
            <Textarea id="obsocor"
                      rows="4"
                      v-model="etapaData.obs" />
          </div>
        </div>

        <div class="col-6 md:col-6" v-if="etapaData.id">
          <Button label="Excluir" icon="pi pi-trash" class="mr-2 p-button-danger p-button-outlined" @click="excluirOcor()"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Fechar" icon="pi pi-ban" class="mr-2 p-button-outlined" @click="sidebarEtapa = false; validEtapa = false;"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Salvar" icon="pi pi-save" class="mr-2 p-button-outlined" @click="saveEtapa()" :loading="loadingBtnSaveEtapa" />
        </div>




      </div>
    </Sidebar>




    <Sidebar v-model:visible="sidebarCotacao" :baseZIndex="1000" position="right" class="p-sidebar-lg">
      <orcamento-detalhe-cotacao
          :orcamentoData="orcamentoData"
          @escolheOutraCotacao="escolheCotacao"
          @hideCotacao="sidebarCotacao = false">
      </orcamento-detalhe-cotacao>
    </Sidebar>



    <Sidebar v-model:visible="sidebarAnalDocs" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <h5>Importar documento</h5>
      <ul v-if="listaimgs && listaimgs[0]">
        <li v-for="file of listaimgs[0]" :key="file">{{ file.name }} - {{ file.size }} bytes</li>
      </ul>
      <div class="grid p-fluid">
        <div class="col-6">
          <FileUpload chooseLabel="Selecionar arquivo" class="p-button-success" mode="basic" name="demo[]" url="./upload.php" accept=".pdf" :maxFileSize="1000000" @select="onFileSelect($event)"  />
        </div>
        <div class="col-6">
          <Button class="mr-2 p-button-outlined" label="Salvar" icon="pi pi-save" :loading="loadingSalvar" @click="saveLeituraDoc" />
        </div>
      </div>
      <div  class="custom-skeleton p-4" v-if="loadingLeituraDoc === true">
        <Skeleton width="100%" height="50px"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
      </div>
      <div class="grid p-fluid mt-3" v-if="leituraDoc">
        <div class="col-12 md:col-12">
          <h5>O cliente não foi encontrado no banco de dados, confirma o cadastro de um novo cliente</h5>
          <div class="p-inputgroup">
                <span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
                  <Checkbox v-model="confirmNovoCli" :binary="true"/>
                </span>
            <InputText placeholder="Confirm" v-model="leituraDoc.cli" />
          </div>
        </div>
        <div class="col-12 md:col-12" v-if="confirmNovoCli">
          <h5>Atualizar Endereço </h5>
          <div class="p-inputgroup">
							<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
								<Checkbox v-model="confirmNovoEnd3" :binary="true"/>
							</span>
            <InputText placeholder="Confirm" v-model="leituraDoc.end3" />
          </div>
        </div>
        <div class="col-6 md:col-6" v-if="confirmNovoCli">
          <div class="p-inputgroup">
							<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
								<Checkbox v-model="confirmNovoEnd1" :binary="true"/>
							</span>
            <InputText placeholder="Confirm" v-model="leituraDoc.end1" />
          </div>
        </div>
        <div class="col-6 md:col-6" v-if="confirmNovoCli">
          <div class="p-inputgroup">
							<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
								<Checkbox v-model="confirmNovoEnd2" :binary="true"/>
							</span>
            <InputText placeholder="Confirm" v-model="leituraDoc.end2" />
          </div>
        </div>
        <div class="col-6 md:col-6">
          <h5>Atualizar Consumo kW/h</h5>
          <div class="p-inputgroup">
							<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
								<Checkbox v-model="confirmNovoCons" :binary="true"/>
							</span>
            <InputText placeholder="Confirm" v-model="leituraDoc.cons" />
          </div>
        </div>
        <div class="col-6 md:col-6">
          <h5>Atualizar Tipo de Padrão </h5>
          <div class="p-inputgroup">
							<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
								<Checkbox v-model="confirmNovoForn" :binary="true"/>
							</span>
            <InputText placeholder="Confirm" v-model="leituraDoc.forn" />
          </div>
        </div>
        <div class="col-6 md:col-6">
          <h5>Atualizar Tensão </h5>
          <div class="p-inputgroup">
							<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
								<Checkbox v-model="confirmNovoTens" :binary="true"/>
							</span>
            <InputText placeholder="Confirm" v-model="leituraDoc.tens" />
          </div>
        </div>
        <div class="col-12 md:col-12 mt-2">
          <h5>Novo Lead </h5>
          <div class="p-inputgroup">

            <InputSwitch v-model="switchNovoLead" />
          </div>
        </div>
      </div>
    </Sidebar>

    <Sidebar v-model:visible="sidebarLead" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <div class="col-12">
        <cliente-detalhe-timeline></cliente-detalhe-timeline>
      </div>
    </Sidebar>

    <ConfirmDialog group="dialog" />


  </div>
</template>
<script>
import ClienteDetalheTimeline from "@/components_esol/clientes/ClienteDetalheTimeline";
import OrcamentoDetalheCotacao from '@/components_esol/orcamentos/OrcamentoDetalheCotacao'
import OrcamentoService from "@/service/OrcamentoService";
import moment from 'moment'

export default {
  components: {
    ClienteDetalheTimeline,
    OrcamentoDetalheCotacao
  },
  data() {
    return {
      financData: null,
      etapasData: null,
      loadingBtnSaveEtapa: false,
      sidebarEtapa: null,
      validEtapa: false,
      etapaData: [],
      switchNovoLead: true,
      radioFaixaValue: null,
      toggleFX1: false,
      toggleFX2: false,
      toggleFX3: false,
      toggleFX4: false,
      sidebarLead: false,
      selectedAutoValueCliente: null,
      autoFilteredCliente: [],
      autoValueCliente: [],
      loadingLeituraDoc: false,
      sidebarAnalDocs: false,
      leituraDoc: null,
      confirmNovoCli: true,
      confirmNovoEnd3: true,
      confirmNovoEnd1: true,
      confirmNovoEnd2: true,
      confirmNovoCons: true,
      confirmNovoForn: true,
      confirmNovoTens: true,

      listaimgs: [],
      tabretinvestData: null,
      tabretinvestTotaisData: null,
      tabdimensData: null,
      dsGraphGeracao: null,
      tabperdasData:null,
      irradItensData: null,
      indicadGeracao: null,
      indicadoresData: null,
      loadingSalvar: false,
      loadingAtuCota: false,
      loadingIncrSist: false,
      loadingPDF: false,
      getcotacao: false,
      loadingData: true,
      sidebarCotacao: false,

      pedidosData: null,
      obrasData: null,
      projetosData: null,

      loadingLeads: true,
      orcamentoService: null,
      leadsData: null,
      orcamentoData: null,
      orctocotaData: null,
      orctoitemsData: null,
      orctosistData: null,
      orctovalorData: null,
      orctocronoData: null,
      orctoclienteData: null,
      orctoFinancData: null,
      itemsModulo: [],
      itemsTensao: [],
      itemsEstrutura: [],

      etapaItems: [
        {name: 'Visita', code: 'Visita'},
        {name: 'Orcto. Definitivo', code: 'Orcto. Definitivo'},
        {name: 'Análise Orçamento', code: 'Análise Orçamento'},
        {name: 'Atual. Orçamento', code: 'Atual. Orçamento'},
        {name: 'Desistência', code: 'Desistência'},
        {name: 'Assinatura Contr.', code: 'Assinatura Contr.'},
        {name: 'Pagamento', code: 'Pagamento'},
        {name: 'Agenda Instal.', code: 'Agenda Instal.'},
        {name: 'Instalação', code: 'Instalação'},
        {name: 'Vistoria', code: 'Vistoria'},
      ],

      riscoinstItems: [
        {name: 'Alto', code: 'AL'},
        {name: 'Médio', code: 'MD'},
        {name: 'Baixo', code: 'BX'},

      ],

      padraoItems: [
        {name: 'Perfil A', code: 'PA'},
        {name: 'Perfil B+', code: 'PBMAIS'},
        {name: 'Perfil B limite', code: 'PBL'},
        {name: 'Perfil B-', code: 'BMENOS'},
      ],

      tipopadraoItems: [
        {name: 'Monofásico', code: 'MN'},
        {name: 'Bifásico', code: 'BI'},
        {name: 'Trifásico', code: 'TR'},
      ],

      tensaopadraoItems: [
        {name: '127v', code: '127'},
        {name: '220v', code: '220'},
        {name: '380v', code: '380'},
      ],

      estruturaItems: [
        {name: 'Solo', code: 'Solo'},
        {name: 'Trapezoidal', code: 'Trapezoidal'},
        {name: 'Fibrocimento Parafuso Metal', code: 'Fibrocimento Parafuso Metal'},
        {name: 'Fibrocimento Parafuso Madeira', code: 'Fibrocimento Parafuso Madeira'},
        {name: 'Ondulada', code: 'Ondulada'},
        {name: 'Romagnole', code: 'Romagnole'},
        {name: 'Laje', code: 'Laje'},
        {name: 'Colonial', code: 'Colonial'},
        {name: 'Zipada', code: 'Zipada'},
      ],
      bancoItems: [
        {name: 'Sicoob', code: 'Sicoob'},
      ],
      dropdownItems: [
        {name: 'ES', code: 'ES'},
        {name: 'MG', code: 'MG'},
        {name: 'RJ', code: 'RJ'}
      ],
      dropdownItem: null,

      barData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'My Second dataset',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },
    }
  },

  created() {
    this.orcamentoService = new OrcamentoService();


  },

  mounted() {

    console.log('mounted orcamntoDetalhe')
    let idorcto = this.$route.params.id


    // EXECUTAR APENAS UMA VEZ... MIGRATE FRESH ANTES
    // console.log('atualizarListas')
    // this.orcamentoService.getListasFS().then((data) => {
    //   console.log('getListasFS')
    //   console.log(data)
    //   this.orcamentoService.savelistas({
    //     data
    //   }).then((data) => {
    //     console.log('savelistas')
    //     console.log(data)
    //   });
    // });



    this.orcamentoService.getssorcto(idorcto).then((data) => {
      console.log('getOrcamento')
      console.log(data)

      if (data === 'nao_permitido') {
        this.$router.replace({ path: '/acessorestrito' })
      }else{
        this.orcamentoData = data[0]
        this.orctocotaData = data[2]
        this.orctoitemsData = data[3]
        this.orctosistData = data[4]
        this.orctovalorData = data[5]
        this.irradItensData = data[6]
        this.tabperdasData = data[7]
        this.dsGraphGeracao = data[8]
        this.tabdimensData = data[9]
        this.tabretinvestData = data[10]
        this.orctocronoData = data[11]
        this.orctoclienteData = data[12]
        this.tabretinvestTotaisData = data[13]
        this.etapasData = data[14]
        this.financData = data[15]

        if (this.etapasData != null){
          console.log('this.etapasData')
          console.log(this.etapasData)
          this.etapasData.forEach((etapa) => {
            etapa.data = moment(etapa.data).toDate()
          });
        }

        // console.log('this.orctoclienteData')
        // console.log(this.orctoclienteData)
        // console.log(this.orctoclienteData.length)

        // this.orctocronoData.validade = new Date(this.orctocronoData.validade)

        if (this.orctocronoData != null) {
          if (this.orctocronoData.validade)
            this.orctocronoData.validade = moment(this.orctocronoData.validade).toDate()
          if (this.orctocronoData.etp1ini)
            this.orctocronoData.etp1ini = moment(this.orctocronoData.etp1ini).toDate()
          if (this.orctocronoData.etp2ini)
            this.orctocronoData.etp2ini = moment(this.orctocronoData.etp2ini).toDate()
          if (this.orctocronoData.etp3ini)
            this.orctocronoData.etp3ini = moment(this.orctocronoData.etp3ini).toDate()
          if (this.orctocronoData.etp4ini)
            this.orctocronoData.etp4ini = moment(this.orctocronoData.etp4ini).toDate()
          if (this.orctocronoData.etp5ini)
            this.orctocronoData.etp5ini = moment(this.orctocronoData.etp5ini).toDate()
          if (this.orctocronoData.etp6ini)
            this.orctocronoData.etp6ini = moment(this.orctocronoData.etp6ini).toDate()
          if (this.orctocronoData.etp7ini)
            this.orctocronoData.etp7ini = moment(this.orctocronoData.etp7ini).toDate()
          if (this.orctocronoData.etp8ini)
            this.orctocronoData.etp8ini = moment(this.orctocronoData.etp8ini).toDate()
        }

        data[1].forEach(classe => {
          if (classe.codigo === 'modulo'){
            this.itemsModulo.push({
              name: classe.descricao, code: classe.descricao
            })
          } else if (classe.codigo === 'fase'){
            this.itemsTensao.push({
              name: classe.descricao, code: classe.descricao
            })
          } else if (classe.codigo === 'estrutura'){
            this.itemsEstrutura.push({
              name: classe.descricao, code: classe.descricao
            })
          }
        })

        if (this.orctoclienteData){
          this.selectedAutoValueCliente = this.orctoclienteData.nome
        }

        if (this.orctosistData && this.orctosistData.faixa > 0){
          // console.log('this.orctosistData.faixa')
          // console.log(this.orctosistData.faixa)

          switch (this.orctosistData.faixa){
            case 1:
              this.toggleFX1 = true
              break
            case 2:
              this.toggleFX2 = true
              break
            case 3:
              this.toggleFX3 = true
              break
            case 4:
              this.toggleFX4 = true
              break
          }
        }

        this.atualizarFaixas()


        setTimeout(() => {
          this.loadingData = false
          this.atualizarIndicValores()

        }, 50)

        this.$router.replace({ path: '/orcamentodetalhe/' + this.orcamentoData.id })
      }

    });




    this.orcamentoService.getLeads().then((data) => {
      console.log('orcamentoDetalhe_0')
      this.leadsData = data
      this.loadingLeads = false
      //console.log(this.leadsData)
      this.leadsData.forEach(lead => lead.date = new Date(lead.date));
    });



    this.orcamentoService.getPedidos().then((data) => {
      this.pedidosData = data
      // console.log(this.pedidosData)
      this.pedidosData.splice(0,3)
      // console.log(this.pedidosData)
      this.pedidosData.forEach(pedido => pedido.date = new Date(pedido.date));
    });

    this.orcamentoService.getObras().then((data) => {
      this.obrasData = data
      // console.log(this.pedidosData)
      this.obrasData.splice(0,5)
      // console.log(this.pedidosData)
      this.obrasData.forEach(obra => obra.date = new Date(obra.date));
    });

    this.orcamentoService.getProjetos().then((data) => {
      this.projetosData = data
      // console.log(this.pedidosData)
      this.projetosData.splice(0,8)
      // console.log(this.pedidosData)
      this.projetosData.forEach(obra => obra.date = new Date(obra.date));
    });


  },


  watch: {
    'orctosistData.grisco': {
      handler(){
        this.calculacustomatele()
        this.atualizarIndicGeracao()
      }
    },
    'orctosistData.perfil': {
      handler(){
        this.calculacustoengenh()
        this.atualizarIndicGeracao()
      }
    },
    'orctosistData.incidsol': {
      handler(){
        this.calculaconsumo()
        this.atualizarIndicGeracao()
      }
    },
    'orctosistData.consmes': {
      handler(){
        this.calculaconsumo()
        this.atualizarIndicGeracao()
      }
    },

    'orctosistData.consmed': {
      handler(){
        this.atualizarIndicGeracao()
      }
    },
    'orctosistData.dispon': {
      handler(){
        this.atualizarIndicGeracao()
      }
    },
    'orctovalorData.fatproj': {
      handler(){
        this.calculaMargemProj()
      }
    },
    'orctovalorData.fatcfix': {
      handler(){
        this.calculaMargemProj()

        // this.atualizarFaixas()
      }
    },
    'orctovalorData.imposto': {
      handler(){
        this.calculatotal()
      }
    },
    'orctovalorData.maoobrap': {
      handler(){
        this.calculamaoobra()
        this.calculatotal()
      }
    },
    'orctovalorData.art': {
      handler(){
          this.calculatotal()
      }
    },
    'orctovalorData.matelep': {
      handler(){
        this.calculamatele()
        this.calculatotal()
      }
    },
    'orctovalorData.engenp': {
      handler(){
        this.calculacustoengenh()
        this.calculatotal()
      }
    },
    'orctovalorData.apolog': {
      handler(){
        this.calculatotal()
      }
    },
    'orctovalorData.custooprp': {
      handler(){
        this.calculacustoopr()
        this.calculatotal()
      }
    },
    'orctovalorData.comissp': {
      handler(){
        this.calculacomiss()
        this.calculatotal()
      }
    },
    'orctovalorData.dbip': {
      handler(){
        this.calculadbi()
        this.calculatotal()
      }
    },
    'orctovalorData.dbir': {
      handler(){
        this.calculadbi()
        this.calculatotal()
      }
    },
    'orctovalorData.margnegp': {
      handler(){
        this.calculamargneg()
        this.calculatotal()
      }
    },
  },


  methods: {

    saveEtapa(){
      console.log('saveEtapa')
      console.log(this.etapaData)
      this.validEtapa = true
      if (this.etapaData.tipo && this.etapaData.data){
        this.loadingBtnSaveEtapa = true

        this.orcamentoService.savessetapa({
          'orcamento_id' : this.orcamentoData.id,
          'etapa': this.etapaData.tipo,
          'data': this.etapaData.data,
          'obs': this.etapaData.obs
            },
        ).then((dataEtapa) => {

          console.log('data saveEtapa')
          console.log(dataEtapa)
          this.etapasData = dataEtapa

          if (this.etapasData != null){
            this.etapasData.forEach((etapa) => {
              etapa.data = moment(etapa.data).toDate()
            });
          }

          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Etapa Confirmada com Sucesso', life: 3000});
          this.sidebarEtapa = false
          this.loadingBtnSaveEtapa = false
        });
      }



    },

    // novaEtapa(){
    //   console.log('novaEtapa')
    // },

    nomeStatus(sigla){

      switch (sigla) {
        case 'EL':
          return 'Elaboração'

        case 'SC':
          return 'Sucesso'

        default:
          return 'Não Definido'

      }

    },

    confirmarOrcto(){
      console.log('excluirFile')
      //console.log(arquivo.id)

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja confirmar o orçamento?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          console.log('confirmou')

          this.orcamentoService.confirmarorcto(this.orcamentoData.id).then((data) => {
            console.log('confirmarorcto')
            console.log(data)
            this.orcamentoData.status = 'SC'
            this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Confirmado com Sucesso', life: 3000});
            // this.orctoclienteData = dataCli[0]
            // let cidadeCli = dataCli[1]
            // if (cidadeCli){
            //   this.orctosistData.incidsol = cidadeCli.anual / 1000
            // }

          });


          // this.orcamentoService.confirmarOrcto(this.orcamentoData.id).then((data) => {
          //   console.log('confirmarOrcto')
          //   console.log(data)
          //
          //
          //   this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Confirmado com Sucesso', life: 3000});
          // })

          // this.clienteService.ssdeletefile(arquivo.id).then((data) => {
          //   console.log('data delete file')
          //   console.log(data)
          //
          //
          //   if (arquivo.tipo === 'conta_energia'){
          //     this.arqContaEnergia = null
          //   } else if (arquivo.tipo === 'relogio'){
          //     this.arqRelogio = null
          //   } else if (arquivo.tipo === 'quadro_distribuicao'){
          //     this.arqQuadroDistribuicao = null
          //   } else if (arquivo.tipo === 'disjuntor_geral'){
          //     this.arqDisjuntorGeral = null
          //   } else if (arquivo.tipo === 'telhado'){
          //     this.arqTelhado = null
          //   } else if (arquivo.tipo === 'faixada'){
          //     this.arqFaixada = null
          //   }
          //
          //
          //   this.$toast.add({severity:'success', summary: 'Arquivo excluído', detail:'As informações foram excluídas com sucesso', life: 3000});
          //
          // })


        },
        reject: () => {

        }
      });
    },

    atualizarFaixas(){
      if (this.orctovalorData && this.orctovalorData.invest > 0) {

        this.orctovalorData.valortotal = this.orctovalorData.invest + this.orctovalorData.valorf

        let valorFx1 = this.orctovalorData.valortotal
        let valorFx2 = this.orctovalorData.valortotal - this.orctovalorData.margneg

        var valcomisbase = this.orctovalorData.invest + this.orctovalorData.maoobra + this.orctovalorData.art + this.orctovalorData.matele +
            this.orctovalorData.engen + this.orctovalorData.apolog  + this.orctovalorData.custoopr

        // console.log('valdbibase')
        // console.log(valdbibase)
        let comic25 = valcomisbase * 0.025
        var valdbibaseC25 = this.orctovalorData.maoobra + this.orctovalorData.art + this.orctovalorData.matele +
            this.orctovalorData.engen + this.orctovalorData.apolog + this.orctovalorData.custoopr + comic25 +
            this.orctovalorData.imposto
        let dbi20 = valdbibaseC25 * 0.20

        // console.log('this.orctovalorData.dbi')
        // console.log(this.orctovalorData.dbi)
        // console.log('dbi20')
        // console.log(dbi20)

        let valorFx3 = this.orctovalorData.valortotal - this.orctovalorData.margneg - this.orctovalorData.comiss + comic25 - this.orctovalorData.dbi + dbi20

        let comic2 = valcomisbase * 0.02
        var valdbibaseC2 = this.orctovalorData.maoobra + this.orctovalorData.art + this.orctovalorData.matele +
            this.orctovalorData.engen + this.orctovalorData.apolog + this.orctovalorData.custoopr + comic2 +
            this.orctovalorData.imposto
        let dbi12 = valdbibaseC2 * 0.12

        let valorFx4 = this.orctovalorData.valortotal - this.orctovalorData.margneg - this.orctovalorData.comiss + comic2 - this.orctovalorData.dbi + dbi12


        this.indicadoresData = {
          'totalfx1': valorFx1,
          'comicfx1': this.orctovalorData.comiss,
          'totalfx2': valorFx2,
          'comicfx2': this.orctovalorData.comiss,
          'totalfx3': valorFx3,
          'comicfx3': comic25,
          'totalfx4': valorFx4,
          'comicfx4': comic2,
        }

        // console.log('indicadoresData.totalfx1')
        // console.log(this.indicadoresData.gestaofx1)
      }
    },

    editCliente() {
      console.log('editCliente')
      // console.log(this.orcamentoData.pessoa_id)

      if (this.orcamentoData.pessoa_id) {
        this.$router.push({ path: `/clientedetalhe/${ this.orcamentoData.pessoa_id }` });
      }else{
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Cliente não selecionado, favor pesquisar e escolher algum cliente', life: 3000});
      }
    },

    novoCliente() {
      console.log('novoCliente')
      // console.log(this.orcamentoData.pessoa_id)
      this.$router.push({ path: `/clientedetalhe/0` });
    },

    selecionarFaixa(){
      // console.log('selecionarFaixa')
      // console.log(this.orctosistData.faixa)
      switch (this.orctosistData.faixa){
        case '1':
          this.orctovalorData.comissp = 3
          this.orctovalorData.dbip = 40
          this.orctovalorData.margnegp = 20
          break
        case '2':
          // console.log('this.orctosistData.faixa22222')
          this.orctovalorData.comissp = 3
          this.orctovalorData.dbip = 40
          this.orctovalorData.margnegp = 0
          break
        case '3':
          this.orctovalorData.comissp = 2.5
          this.orctovalorData.dbip = 20
          this.orctovalorData.margnegp = 0
          break
        case '4':
          this.orctovalorData.comissp = 2
          this.orctovalorData.dbip = 12
          this.orctovalorData.margnegp = 0
          break
      }



    },

    abrirMapCli(){
      console.log('abrirMapCli')
      if (this.orctoclienteData && this.orctoclienteData.rua && this.orctoclienteData.numero && this.orctoclienteData.bairro && this.orctoclienteData.cidade) {
        //const urlSuffix = "Rod. Governador Mario Covas, Serra, ES, 29162-703"
        const urlSuffix = this.orctoclienteData.rua + ' ' + this.orctoclienteData.numero + ', ' + this.orctoclienteData.cidade + ', ' + this.orctoclienteData.estado + ', ' + this.orctoclienteData.cep
        console.log('urlSuffix')
        console.log(urlSuffix)
        window.open(
            "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
            "_blank"
        );
      }else{
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Endereço não disponível, favor preencher dados no cliente', life: 3000});
      }
    },

    selecionaCliente(event) {
      console.log('selecionaCliente')
      // console.log(event.value)
      this.orcamentoData['pessoa_id'] = event.value.id
      this.orcamentoData['cliente'] = event.value.nome
      this.orcamentoService.getCliente(event.value.id).then((dataCli) => {
        console.log('getCliente')
        console.log(dataCli[0])
        this.orctoclienteData = dataCli[0]
        let cidadeCli = dataCli[1]
        if (cidadeCli){
          this.orctosistData.incidsol = cidadeCli.anual / 1000
        }

      });

    },

    searchCliente(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCliente = [...this.autoValueCliente];
        }
        else {
          // console.log('vai pesquisar')
          this.orcamentoService.getPesqCliente().then((data) => {
            // console.log('getPesqCliente')
            // console.log(data)
            this.autoValueCliente = data
            this.autoFilteredCliente = this.autoValueCliente.filter((pessoa) => {
              if (pessoa.nome && event.query){
                console.log(pessoa.nome)
                return pessoa.nome.toLowerCase().startsWith(event.query.toLowerCase());
              }
            });
          });

        }
      }, 250);
    },

    onFileSelect(event) {
      this.loadingLeituraDoc = true
      console.log('onFileSelect')
      console.log(event.files)
      console.log(event.files[0])
      this.listaimgs.push(event.files)

      if (event.files[0]){
        this.orcamentoService.getAnalConta({'files': event.files[0]}).then((dataConta) => {
          console.log('getAnalConta')
          console.log(dataConta)

          this.leituraDoc = dataConta[0];
          this.loadingLeituraDoc = false
        });
      }
    },

    atualizarIndicGeracao() {
      if (this.orctosistData && this.orctosistData.consmed > 0 && this.indicadGeracao) {
        console.log('atualizarIndicGeracao')
        this.indicadGeracao.geracdesej = this.orctosistData.consmed - this.orctosistData.dispon
      }
    },

    atualizarIndicValores(){
      if (this.loadingData === false) {
        if (this.orctovalorData && this.orctovalorData.invest > 0) {
          console.log('atualizarIndicValores')
          console.log('valorf')
          console.log(this.orctovalorData.valorf)

          this.orctovalorData.valortotal = this.orctovalorData.invest + this.orctovalorData.valorf
          this.orctovalorData.partgest = this.orctovalorData.valorf / (this.orctovalorData.invest + this.orctovalorData.valorf) * 100

          if (this.orctovalorData.margnegp && this.orctovalorData.margnegp > 0)
            this.orctovalorData.margemnegocia = this.orctovalorData.margnegr - this.orctovalorData.margnegp
          else
            this.orctovalorData.margemnegocia = 0

          if (this.orctovalorData.comissp && this.orctovalorData.comissp > 0)
            this.orctovalorData.desccomis = this.orctovalorData.comissr - this.orctovalorData.comissp
          else
            this.orctovalorData.desccomis = 0

          if (this.orctovalorData.dbip && this.orctovalorData.dbip > 0)
            this.orctovalorData.descdbi = this.orctovalorData.dbir - this.orctovalorData.dbip
          else
            this.orctovalorData.descdbi = 0


          if (this.irradItensData && this.irradItensData.length > 0) {
            // console.log('this.irradItensData_0')
            // console.log(this.irradItensData.length)

            var irradmedia = (this.irradItensData[1]['jan'] + this.irradItensData[1]['fev'] + this.irradItensData[1]['mar'] + this.irradItensData[1]['abr'] +
                this.irradItensData[1]['mai'] + this.irradItensData[1]['jun'] + this.irradItensData[1]['jul'] + this.irradItensData[1]['ago'] + this.irradItensData[1]['set'] +
                this.irradItensData[1]['out'] + this.irradItensData[1]['nov'] + this.irradItensData[1]['dez']) / 12

            try {
              var somageracao = parseFloat(this.irradItensData[3]['jan'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['fev'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['mar'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['abr'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['mai'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['jun'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['jul'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['ago'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['set'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['out'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['nov'].replace(/\D/g, "") / 100) +
                  parseFloat(this.irradItensData[3]['dez'].replace(/\D/g, "") / 100)
            } catch (e) {
              somageracao = 0
            }

            this.indicadGeracao = {
              'geracdesej': this.orctosistData.consmed - this.orctosistData.dispon,
              'irradmedia': irradmedia,
              'germedmes': somageracao / 12,
              'germedano': somageracao,
              'produtiv': somageracao / this.orctosistData.modulos
            }
          }

        }
      }
    },

    calculaconsumo() {
      if (this.loadingData === false) {
        this.orctosistData.consdia = this.orctosistData.consmes / 30
        this.orctosistData.ppico1 = this.orctosistData.consdia
        if (this.orctosistData.incidsol > 0) {
          this.orctosistData.ppico1 = this.orctosistData.consdia / this.orctosistData.incidsol
          this.orctosistData.ppico2 = this.orctosistData.ppico1 / 0.8
          this.orcamentoData.potencia = this.orctosistData.ppico2
        }

      }
    },

    calculatotal(){
      if (this.loadingData === false) {
        console.log('calculatotal')
        this.orctovalorData.valorf = this.orctovalorData.maoobra + this.orctovalorData.art + this.orctovalorData.matele +
            this.orctovalorData.engen + this.orctovalorData.apolog  + this.orctovalorData.custoopr + this.orctovalorData.comiss +
            this.orctovalorData.imposto + this.orctovalorData.dbi + this.orctovalorData.margneg

        console.log('this.orctovalorData.custoopr')
        // console.log(this.orctovalorData.valorf)
        // console.log(this.orctovalorData.custoopr)

        // this.orctovalorData.valortotal = 0

        this.atualizarIndicValores()
        this.atualizarFaixas()
      }
    },

    calculacustomatele() {
      if (this.loadingData === false) {
        // console.log('calculacustomatele')
        // console.log(this.orctosistData.grisco)
        if (this.orctovalorData.matelep === null){
          if (this.orctosistData.grisco === 'AL') {
            this.orctovalorData.mateler = 9
            this.orctovalorData.matele = this.orctovalorData.invest * 9 / 100
          } else if (this.orctosistData.grisco === 'MD') {
            this.orctovalorData.mateler = 7.5
            this.orctovalorData.matele = this.orctovalorData.invest * 7.5 / 100
          } else if (this.orctosistData.grisco === 'BX') {
            this.orctovalorData.mateler = 6
            this.orctovalorData.matele = this.orctovalorData.invest * 6 / 100
          }
        }else {
          this.orctovalorData.matele = this.orctovalorData.matelep
        }
        this.calculatotal()
      }
    },

    calculacustoengenh(){
      if (this.loadingData === false) {
        if (this.orctovalorData.engenp === null){
          if (this.orctosistData.perfil === 'PA') {
            this.orctovalorData.engen = 2500
          } else if (this.orctosistData.perfil === 'PBMAIS') {
            this.orctovalorData.engen = 1600
          } else if (this.orctosistData.perfil === 'PBL') {
            this.orctovalorData.engen = 1000
          } else if (this.orctosistData.perfil === 'BMENOS') {
            this.orctovalorData.engen = 600
          }
        }else {
          this.orctovalorData.engen = this.orctovalorData.engenp
        }
        this.calculatotal()
      }
    },

    calculaMargemProj(){
      if (this.loadingData === false) {
        if (this.orctovalorData.fatproj && this.orctovalorData.fatproj > 0){
          this.orctovalorData.fatmarg = this.orctovalorData.fatproj * 70 / 100
          this.orctovalorData.fatpartic = (this.orctovalorData.invest / (this.orctovalorData.fatproj * 0.7)) * this.orctovalorData.fatcfix
          if (!this.orctovalorData.custooprp || this.orctovalorData.custooprp == 0){
            this.orctovalorData.custoopr = this.orctovalorData.fatpartic
          }
          this.calculatotal()
          // this.atualizarIndicValores()

        //)
      }
        else
          this.orctovalorData.fatmarg = 0
      }
    },
    calculamaoobra(){
      if (this.loadingData === false) {
        // console.log('this.orctovalorData.maoobrap')
        // console.log(this.orctovalorData.maoobrap)
        if (this.orctovalorData.maoobrap === null) {
          this.orctovalorData.maoobra = this.orctovalorData.maoobrar * this.orctosistData.modulos
        } else {
          this.orctovalorData.maoobra = this.orctovalorData.maoobrap * this.orctosistData.modulos
        }
        this.calculatotal()
      }
    },
    calculamatele(){
      if (this.loadingData === false) {
        if (this.orctovalorData.matelep === null) {
          this.orctovalorData.matele = this.orctovalorData.invest * this.orctovalorData.mateler / 100
        }else{
          this.orctovalorData.matele = this.orctovalorData.matelep
        }
        this.calculatotal()
      }
    },
    calculaengen(){
      if (this.loadingData === false) {
        if (this.orctovalorData.engenp === null) {
          this.orctovalorData.engen = this.orctovalorData.engenr
        }else{
          this.orctovalorData.engen = this.orctovalorData.engenp
        }
        this.calculatotal()
      }
    },
    calculacustoopr(){
      if (this.loadingData === false) {
        if (this.orctovalorData.custooprp === null) {
          this.orctovalorData.custoopr = this.orctovalorData.fatpartic
        }else{
          this.orctovalorData.custoopr = this.orctovalorData.custooprp
        }
        this.calculatotal()
      }
    },
    calculacomiss(){
      if (this.loadingData === false) {
        var valb = this.orctovalorData.invest + this.orctovalorData.maoobra + this.orctovalorData.art + this.orctovalorData.matele +
            this.orctovalorData.engen + this.orctovalorData.apolog  + this.orctovalorData.custoopr
        console.log('valb')
        console.log(valb)
        if (this.orctovalorData.comissp === null) {
          this.orctovalorData.comiss = valb * this.orctovalorData.comissr / 100
        }else{
          this.orctovalorData.comiss = valb * this.orctovalorData.comissp / 100
        }
        this.calculatotal()
      }
    },
    calculadbi() {
      if (this.loadingData === false) {
        var valb = this.orctovalorData.maoobra + this.orctovalorData.art + this.orctovalorData.matele +
            this.orctovalorData.engen + this.orctovalorData.apolog + this.orctovalorData.custoopr + this.orctovalorData.comiss +
            this.orctovalorData.imposto

        if (this.orctovalorData.dbip === null) {
          this.orctovalorData.dbi = valb * this.orctovalorData.dbir / 100
        }else{
          this.orctovalorData.dbi = valb * this.orctovalorData.dbip / 100
        }
        this.calculatotal()
      }
    },
    calculamargneg() {
      if (this.loadingData === false) {
        var valb = this.orctovalorData.maoobra + this.orctovalorData.art + this.orctovalorData.matele +
            this.orctovalorData.engen + this.orctovalorData.apolog + this.orctovalorData.custoopr + this.orctovalorData.comiss +
            this.orctovalorData.imposto + this.orctovalorData.dbi
        if (this.orctovalorData.margnegp === null) {
          this.orctovalorData.margneg = valb * this.orctovalorData.margnegr / 100
        }else{
          this.orctovalorData.margneg = valb * this.orctovalorData.margnegp / 100

        }
        this.calculatotal()
      }
    },

    imprimitPDF(){
      let idorcto = this.$route.params.id
      this.loadingPDF = true

      this.orcamentoService.getssorctopdf(idorcto).then(() => {
        this.loadingPDF = false
      })

    },

    escolheCotacao(cotacao){
      console.log('escolheCotacao_nv_0')
      console.log(cotacao)

      this.loadingData = true

      this.orctocotaData = cotacao[0]
      this.orctoitemsData = cotacao[1]
      // this.orcamentoData = cotacao[2]
      this.orctosistData = cotacao[3]
      this.orctovalorData = cotacao[4]

      this.loadingData = false
      this.atualizarFaixas()
      this.atualizarIndicValores()



      // this.irradItensData = cotacao[6]
      // this.tabperdasData = cotacao[7]
      // this.dsGraphGeracao = cotacao[8]

    },

    abrirPedido() {
      console.log('abrirPedido')
      if (this.orctocotaData && this.orctocotaData.codigo) {
        window.open('https://fortlevsolar.app/update-order/' + this.orctocotaData.codigo, '_blank');
      }else{
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Cotação não disponível, favor escolher nova cotação', life: 3000});
      }
    },

    cotarSistemas() {
      console.log('cotarSistemas')
      // this.orctovalorData.invest = 1000

      this.getcotacao = true
      console.log('cotarSistemas')
      console.log(this.orcamentoData.modulocotar)
      //this.getcotacao = true
      if (this.orcamentoData.potencia > 0 && this.orcamentoData.tensao && this.orcamentoData.estrutura){
        if (this.orcamentoData.id) {

            this.orcamentoService.savessorcto({
              'orcto': this.orcamentoData,
              'sist': this.orctosistData,
              'valor': this.orctovalorData,
              'crono': this.orctocronoData
            }).then(() => {
              // console.log('orcamentoDetalhe_0')
              // console.log(data.id)
              // this.orcamentoData = data
            });

            this.sidebarCotacao = true

        }else{
          this.$toast.add({severity:'error', summary: 'Validação', detail:'Favor clicar em salvar antes de realizar cotação', life: 3000});
        }
      }
    },

    incrementarSistemas(){
      if (this.orctocotaData && this.orctocotaData.codigo) {
        console.log('incrementarSistemas')
        this.loadingIncrSist = true
        this.orcamentoService.getIncremSist({
          codped: this.orctocotaData.codigo,
          orctoid: this.orcamentoData.id.toString()
        }).then((dataIncrem) => {
          console.log('dataIncrem')
          console.log(dataIncrem)

          this.orcamentoService.getAtualizarCota({
            codped: this.orctocotaData.codigo,
            orctoid: this.orcamentoData.id.toString()
          }).then((dataCotacao) => {
            // console.log('getGeradoresFS')
            // console.log(dataCotacao)
            this.orcamentoService.savecotacao({dataCotacao}).then((cotacao) => {
              // console.log('savecotacao')
              // console.log(dataSaveCota)
              this.orctocotaData = cotacao[0]
              this.orctoitemsData = cotacao[1]
              // this.orcamentoData = cotacao[2]
              this.orctosistData = cotacao[3]
              this.orctovalorData = cotacao[4]
              this.loadingIncrSist = false
              this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
            });
          });
        });
      }else{
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Cotação não disponível, favor escolher nova cotação', life: 3000});
      }
    },


    atualizarSistemas() {
      if (this.orctocotaData && this.orctocotaData.codigo) {
        console.log('atualizarSistemas')
        this.loadingAtuCota = true
        this.orcamentoService.getAtualizarCota({
          codped: this.orctocotaData.codigo,
          orctoid: this.orcamentoData.id.toString()
        }).then((dataCotacao) => {
          // console.log('getGeradoresFS')
          // console.log(dataCotacao)
          this.orcamentoService.savecotacao({dataCotacao}).then((cotacao) => {
            // console.log('savecotacao')
            // console.log(dataSaveCota)
            this.orctocotaData = cotacao[0]
            this.orctoitemsData = cotacao[1]
            // this.orcamentoData = cotacao[2]
            this.orctosistData = cotacao[3]
            this.orctovalorData = cotacao[4]
            this.loadingAtuCota = false
            this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
          });
        });
      }else{
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Cotação não disponível, favor escolher nova cotação', life: 3000});
      }
    },

    saveLeituraDoc() {

      console.log('saveLeituraDoc')
      this.sidebarAnalDocs = false
      this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});

    },

    saveOrcamento() {
      console.log('saveOrcamento')
      console.log(this.orctocronoData.validade)
      this.loadingSalvar = true

      this.orcamentoService.savessorcto({
        'orcto': this.orcamentoData,
        'sist': this.orctosistData,
        'valor': this.orctovalorData,
        'crono': this.orctocronoData,
        'financ': this.financData
      }).then((data) => {
        console.log('orcamentoDetalhe_0')
        console.log(data[0].id)
        this.orcamentoData = data[0]
        this.irradItensData = data[1]
        this.tabperdasData = data[2]
        this.dsGraphGeracao = data[3]
        this.tabdimensData = data[4]
        this.tabretinvestData = data[5]
        this.tabretinvestTotaisData = data[6]


        this.atualizarIndicValores()

        this.loadingSalvar = false
        this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
      });
    },


    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
      return;
    },

    formatCurrency(value) {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
